import React from 'react';

function GuitarTabs() {
    return (
        <div>
            <h1>Chernobyl Hamster Notes</h1>
            {/* <source src="chernobylhamster.mp4" type="audio/mpeg"> */}
            <p>Mainly all minor bar chords like this:</p>
            <pre>{`
                E|--------------
                B|-8------------
                G|-9------------
                D|-9------------
                A|-7------------
                E|--------------
            `}</pre>
            <p>The intro is basically like this:</p>
            <pre>{`
                E|---------------------------------------------------------------------
                B|-----8-8------10-----8-8------------8-8------10-----8-8----8-8-7-7---
                G|-----9-9------9------9-9------------9-9------9------9-9----8-8-8-8---
                D|-----9-9------10-----9-9------------9-9------10-----9-9----7-7-7-7---
                A|-7-7-7-7---------7-7-7-7--------7-7-7-7---------7-7-7-7--------------
                E|----------7-8-8-----------7-8-7----------7-8-8-----------7-8-8-7-7---
            `}</pre>
            <p>The hardest part is probably figuring out the strum patterns and muting strings.</p>
            <p>The best tools you can have are your ears, the recording, and if possible a program like Ableton Live, Audacity,
                SoundStretch, etc. to slow the audio down while keeping it a high quality so you can still relatively clearly hear what
                is going on.</p>
        </div>
    );
}

export default GuitarTabs;
