import React from 'react';

function Home() {
    return (
        <div>
            <h1>Welcome</h1>
            {/*<p>This is the home page of Pat Garner</p>*/}
        </div>
    );
}

export default Home;